import i18n from "./i18";

// map language to url
const languageMap = {
  "en-US": "",
  zh: "zh",
  es: "es",
  // el: "el",
  // ja: 'ja',
};

const languageFirebaseMap = {
  "en-US": "en",
  zh: "zh_cn",
  es: "es",
  // el: "el",
  // ja: 'ja',
};

// get language given the current url
// input: a url string
// output a url string
const getUrLangVal = (curUrl) => {
  // split url
  let curUrlArr = curUrl.split("/");

  let urlLangVal =
    curUrlArr.length > 1 && curUrlArr[1] in languageMap
      ? languageMap[curUrlArr[1]]
      : "";
  return urlLangVal;
};


const getRedirectPageUrl = (lang, curUrl) => {
  // language being used not detected
  if (!(lang in languageMap)) return curUrl;

  // split url
  let curUrlArr = curUrl.split("/");

  // get language value represented by url
  let urlLangVal = getUrLangVal(curUrl);

  if (urlLangVal === languageMap[lang]) {
    // language being used and url align with each other
    return curUrl;
  } else {
    // language being used and url are not aligned
    // english to other languages
    if (urlLangVal === "") {
      curUrlArr.splice(1, 0, languageMap[lang]);
    }
    // other language to english
    else if (lang === "en-GB" || lang === "en-US") {
      curUrlArr.splice(1, 1);
    }
    // other language to other language
    else {
      curUrlArr.splice(1, 1, languageMap[lang]);
    }
  }

  let res = curUrlArr.join("/");
  if (res === "") return "/";
  return res;
};

const getLanguageUrl = (curUrl, targetUrl) => {
  const lang = getUrLangVal(curUrl);

  if (lang.length === 0) return targetUrl;
  else return "/" + lang + targetUrl;
};


// construct a url given a base

const getTargetUrl = (baseUrl) => {
  let lang = i18n.language;

  // unknown language
  if (!(lang in languageMap)) return baseUrl;

  // map
  let mappedLang = languageMap[lang];

  // english
  if (mappedLang.length === 0) return baseUrl;
  
  return "/" + lang + baseUrl;
};




export {
  languageMap,
  languageFirebaseMap,
  getUrLangVal,
  getTargetUrl,
  getRedirectPageUrl,
  getLanguageUrl
};
