/* eslint-disable no-use-before-define */
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from '@mui/material';
import {
  Briefcase as BriefcaseIcon,
  User as UserIcon,
  Trello as TrelloIcon,
  Star as StarIcon,
  Bookmark as BookmarkIcon,
} from 'react-feather';
import Logo from 'components/Logo';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useTier from 'hooks/useTier';
import NavItem from './NavItem';
import UserInfo from './UserInfo';

const sections = [
  {
    items: [
      {
        title: 'references', // t('globals.references')
        icon: BriefcaseIcon,
        href: '/home',
      },
      {
        title: 'projects', // t('globals.projects')
        icon: BookmarkIcon,
        href: '/projects',
      },
      {
        title: 'account', // t('globals.account')
        icon: UserIcon,
        href: '/account',
      },
      {
        title: 'apps', // t('globals.apps')
        icon: TrelloIcon,
        href: '/app',
      },
      {
        title: 'premium', // t('globals.premium')
        icon: StarIcon,
        href: '/go-premium',
      },
    ],
  },
];

const renderNavItems = ({ items, pathname, depth = 0, Trans }) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, Trans }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({ acc, pathname, item, depth, Trans }) => {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(
      {
        path: item.href,
      },
      pathname
    );

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        Trans={Trans}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          Trans: Trans,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        Trans={Trans}
      />
    );
  }

  return acc;
};

const NavBar = ({ user, openMobile, handles, Trans }) => {
  const location = useLocation();
  const tier = useTier();
  const isMountedRef = useIsMountedRef();

  const onMobileClose = handles.onMobileClose;

  useEffect(() => {
    if (isMountedRef.current && openMobile) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, location.pathname]);

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display='flex' justifyContent='center'>
            <Logo user={user} />
          </Box>
        </Hidden>
        <Box p={2}>
          <UserInfo tier={tier} user={user} Trans={Trans} />
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section, key) => (
            <List
              key={key}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                depth: 0,
                Trans: Trans,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius='borderRadius' bgcolor='background.dark'>
            <Typography variant='h6' color='textPrimary'>
              <Trans i18nKey='layout.DashboardLayout.NavBar.question'>
                Need Help?
              </Trans>
            </Typography>
            <Link
              variant='subtitle1'
              color='secondary'
              component={RouterLink}
              to='/support'
              sx={{ textDecoration: 'none !important' }}
            >
              <Trans i18nKey='layout.DashboardLayout.NavBar.support'>
                Check our support center
              </Trans>
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor='left'
        sx={{ display: { xs: 'block', lg: 'none' } }}
        PaperProps={{
          sx: {
            width: 256,
            // top: "65px  !important",
          },
        }}
        onClose={onMobileClose}
        open={openMobile}
        variant='temporary'
      >
        {content}
      </Drawer>
      <Drawer
        anchor='left'
        sx={{
          display: { xs: 'none', lg: 'block' },
        }}
        PaperProps={{
          sx: {
            width: '256px !important',
            top: '64px !important',
            height: 'calc(100% - 64px) !important',
          },
        }}
        open
        variant='persistent'
      >
        {content}
      </Drawer>
    </>
  );
};

export default NavBar;
