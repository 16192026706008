import firebase from 'firebase/compat/app';

const app = firebase.initializeApp({
  apiKey: "AIzaSyC28ivBgBPjDIzvPrB3nuQGc13XQQbwPrY",
  authDomain: "bibnext.com",
  databaseURL: "https://citasion-35277.firebaseio.com",
  projectId: "citasion-35277",
  storageBucket: "citasion-35277.appspot.com",
  messagingSenderId: "1086335444463",
  appId: "1:1086335444463:web:4c1689893c8e1eb3c2021a"
});

export default app;
export {firebase};
