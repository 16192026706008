import {  Box, Divider } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { getUrLangVal } from 'utils/languageMap';
import NavList from "./NavList";
import { useGuideNavData } from "hooks";

const Content = () => {
  const { pathname } = useLocation();
  const lang = getUrLangVal(pathname);
  const data = useGuideNavData(lang);

  console.log(data);

  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        width: 310,
        marginTop: (theme) => theme.spacing(8),
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Divider />
      <Box p={2}>
        {data.map((section, index) => (
          <NavList section={section} key={index}/>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
