import axios from "axios";
import getIdToken from "./getToken";
import { Navigate } from "react-router-dom";

// config axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

// request interceptor
axiosInstance.interceptors.request.use(
  (req) => {
    if (axiosInstance.defaults.headers.common["Authorization"]) return req;
    else throw new Error("Authorization token not found");
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!!error.response && error.response.status === 404) {
      return <Navigate to="/notFound" replace />;
    } else if (!!error.response && error.response.status === 403) {
      return <Navigate to="/notAuthorized" replace />;
    } else if (!!error.response && error.response.status === 400) {
      return error;
    } else {
      return <Navigate to="/error" replace />;
    }
  }
);

// set auth token function
const setAuthToken = async (user) => {
  if (user) {
    let token = await getIdToken(user);
    //applying token
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    //deleting the token from header
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// export the instance
export { axios, axiosInstance, setAuthToken };
