/* eslint-disable no-use-before-define */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer, List, ListSubheader } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import NavItem from "./NavItem";
import { useTranslation } from "react-i18next";
import { getTargetUrl } from "utils/languageMap";


const renderNavItems = ({ items, depth = 0 }) => {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, depth }), [])}
    </List>
  );
};


const reduceChildRoutes = ({ acc, item, depth = 0 }) => {
  if (item.items) {
    acc.push(
      <NavItem depth={depth} key={item.href} title={item.title}>
        {renderNavItems({
          items: item.items,
          depth: depth + 1,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        key={item.href}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const sections = [
    {
      items: [
        {
          title: t("globals.terms"),
          href: getTargetUrl("/legal/terms"),
        },
        {
          title: t("globals.privacy"),
          href: getTargetUrl("/legal/privacy"),
        },
        {
          title: t("legals:layouts.LegalLayout.NavBar.extensionPrivacy"),
          href: getTargetUrl("/legal/privacy-extension"),
        },
        {
          title: t("legals:layouts.LegalLayout.NavBar.cookieNotice"),
          href: getTargetUrl("/legal/cookie-notice"),
        },
        {
          title: t("legals:layouts.LegalLayout.NavBar.personalInfoRequests"),
          href: getTargetUrl("/legal/personal-info-requests"),
        },
      ],
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map((section, key) => (
            <List
              key={key}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                depth: 0,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        sx={{ display: { xs: "block", lg: "none" } }}
        PaperProps={{
          sx: {
            width: 256,
            top: "65px  !important",
          },
        }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>

      <Drawer
        anchor="left"
        sx={{
          display: { xs: "none", lg: "block" },
        }}
        PaperProps={{
          sx: {
            width: "18%  !important",
            top: "65px  !important",
            height: "calc(100% - 65px) !important",
          },
        }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
