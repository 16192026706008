import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Link, Toolbar, IconButton, SvgIcon, Typography } from "@mui/material";
import Logo from "components/Logo";
import MenuIcon from "@mui/icons-material/Menu";
import AppBarCustomized from "./AppBarCustomized";
import { getTargetUrl } from "utils/languageMap";
import LanguagePicker from "components/LanguagePicker";

const MenuAppBar = ({ user, handles, t, menu = false }) => {
  const location = useLocation();

  if (menu)
    return (
      <AppBarCustomized>
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton
            sx={{ display: { xs: "block", lg: "none" } }}
            color="inherit"
            onClick={handles.onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <Logo sx={{ display: { xs: "block", md: "none" } }} />
          <Box ml={2} flexGrow={1}>
            <div style={{ float: "right", marginRight: 30 }}>
              <LanguagePicker />
            </div>
          </Box>
          <Box ml={2}>
            <Typography variant="h4" align="right">
              {location.pathname === "/login" ? (
                <Link
                  to={getTargetUrl("/register")}
                  component={RouterLink}
                  sx={{
                    placeItems: "center",
                    position: "relative !important",
                    color: "white !important",
                    textDecoration: "none !important",
                  }}
                >
                  {t("views.auth.LoginView.signUp")}
                </Link>
              ) : (
                <Link
                  to={getTargetUrl("/login")}
                  component={RouterLink}
                  sx={{
                    placeItems: "center",
                    position: "relative !important",
                    color: "white !important",
                    textDecoration: "none !important",
                  }}
                >
                  {t("globals.login")}
                </Link>
              )}
            </Typography>
          </Box>
        </Toolbar>
      </AppBarCustomized>
    );

  return (
    <AppBarCustomized>
      <Toolbar sx={{ minHeight: 64 }}>
        <Logo sx={{ display: { xs: "block", md: "none" } }} />
        <Box ml={2} flexGrow={1}>
          <div style={{ float: "right", marginRight: 30 }}>
            <LanguagePicker />
          </div>
        </Box>
        <Box ml={2}>
          <Typography variant="h4" align="right">
            {location.pathname === "/login" ? (
              <Link
                to={getTargetUrl("/register")}
                component={RouterLink}
                sx={{
                  placeItems: "center",
                  position: "relative !important",
                  color: "white !important",
                  textDecoration: "none !important",
                }}
              >
                {t("views.auth.LoginView.signUp")}
              </Link>
            ) : (
              <Link
                to={getTargetUrl("/login")}
                component={RouterLink}
                sx={{
                  placeItems: "center",
                  position: "relative !important",
                  color: "white !important",
                  textDecoration: "none !important",
                }}
              >
                {t("globals.login")}
              </Link>
            )}
          </Typography>
        </Box>
      </Toolbar>
    </AppBarCustomized>
  );
};

MenuAppBar.propTypes = {
  user: PropTypes.object,
  handles: PropTypes.object,
  t: PropTypes.func,
};

export default MenuAppBar;
