import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from '../utils/axios';

const useSubscription = () => {
  const { user } = useAuth();

  const fetchSubscription = async () => {
    if (user === null) return null;
    await setAuthToken(user);
    let response = await axiosInstance.get(`/subscription`);
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getSubscription', user],
    queryFn: () => fetchSubscription(),
  });

  return data;
};

export default useSubscription;
export { useSubscription };
