import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createWebTheme } from './theme';
import GlobalStyles from './components/GlobalStyles';
import GoogleAnalytics from './components/GoogleAnalytics';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import router from './routes';
import { THEMES } from './constants';
import './css/App.css';

// Create a react query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to 120 seconds
      staleTime: 10000 * 120,
    },
  },
});

const App = () => {
  const theme = createWebTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GlobalStyles />
          {window.location.hostname !== 'localhost' && <GoogleAnalytics />}
          <RouterProvider router={router} />
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
