import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';

const useCitationNumberProject = (email, id) => {
  const { user } = useAuth();

  const fetchCitationNumber = async () => {
    if (user === null) return null;
    await setAuthToken(user);

    let response = await axiosInstance.get(
      `/projects/number/${email}/${id}`
    );

    console.log(response.data.result)
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['projectCitationNumber', email, id],
    queryFn: () => fetchCitationNumber(),
  });

  return data;
};

export default useCitationNumberProject;
export { useCitationNumberProject };
