import { useEffect, useState } from "react";
import { Typography, Menu, MenuItem, Tooltip, IconButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Globe as GlobeIcon } from "react-feather";
import { i18n, supportedLngs } from "../utils/i18";
import {
  languageMap,
  getUrLangVal,
  getRedirectPageUrl,
} from "../utils/languageMap";
import useIsMountedRef from "../hooks/useIsMountedRef";

const LanguagePicker = () => {
  const getLanguage = (language) => {
    if (typeof language !== "string" || language === "") return "English";

    // loading international library
    const languageNames = new Intl.DisplayNames([language], {
      type: "language",
    });

    if (languageNames.of(language) === "American English") return "English";
    return languageNames.of(language);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [langPref, setLangPref] = useState(
    i18n.language === "" || typeof i18n.language === "undefined"
      ? "en-US"
      : i18n.language
  );
  const [langDisplay, setLangDisplay] = useState(getLanguage(langPref));
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current) setLangDisplay(getLanguage(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    let urlLang = getUrLangVal(location.pathname);

    if (languageMap[i18n.language] !== urlLang) {
      // greek is not translated yet
      // let homepagePaths = ["/", "/zh/", "/zh", "/es", "/es/", "/el", "/el/"];
      let homepagePaths = ["/", "/zh/", "/zh", "/es", "/es/"];
      if (homepagePaths.includes(location.pathname)) {
        // homepage
        // redirection
        navigate(getRedirectPageUrl(i18n.language, location.pathname));
      } else {
        // other pages
        // change language
        let targetLang;

        let otherLanguages = Object.values(languageMap);
        otherLanguages.shift();

        if (otherLanguages.includes(urlLang)) targetLang = urlLang;
        else targetLang = "en-US";

        // refresh if necessary
        i18n.changeLanguage(targetLang).then(() => {
          console.log("will refresh now");
          window.location.reload();
        });
      }
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const value = event.target.getAttribute("value");
    if (value !== null && value !== langPref) {
      i18n.changeLanguage(value).then(() => {
        navigate(getRedirectPageUrl(value, location.pathname));
      });
    }
    setAnchorEl(null);
  };

  const getLanguages = () => {
    const data = supportedLngs;
    // data.sort();

    const results = data.map((lang) => {
      return {
        rfc5646: lang,
        displayName: getLanguage(lang),
        selected: lang === langPref ? true : false,
      };
    });

    return results;
  };

  const generateLanguageItems = () => {
    let languages = getLanguages();
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {languages.map((item) => (
          <MenuItem
            key={`l${item.rfc5646}`}
            value={item.rfc5646}
            onClick={handleClose}
            style={{ color: "#3949ab" }}
          >
            <GlobeIcon style={{ pointerEvents: "none" }} color="#3949ab" />
            &nbsp;&nbsp;{item.displayName}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Tooltip title="Language">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ color: "white" }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <GlobeIcon color="white" />
          &nbsp;&nbsp;
          <Typography color="white" variant="h4">
            {langDisplay}
          </Typography>
        </IconButton>
      </Tooltip>

      {generateLanguageItems()}
    </>
  );
};

export default LanguagePicker;
