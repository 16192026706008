import { Outlet } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import useAuth from "hooks/useAuth";
import TopBar from "components/TopBar";
import FootBar from "components/FootBar";
import { Trans } from "react-i18next";


const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  minHeight: "100%",
  flex: "1 1 auto",
  paddingTop: "64px"
}));


const SimpleDashboardLayout = () => {
  const { user, logout } = useAuth();

  // top bar open state & function
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const onMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Wrapper>
        <TopBar
          user={user}
          handles={{ onMobileNavOpen: onMobileNavOpen, logout: logout }}
          Trans={Trans}
        />
        <div style={{ minHeight: "calc(100vh - 365px)" }}>
          <Outlet />
        </div>
        <FootBar style={{ width: "100%", minHeight: "300px" }} />
      </Wrapper>
    </div>
  );
};

export default SimpleDashboardLayout;
