import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';

const useCitationNumber = () => {
  const { user } = useAuth();
  const email = user.email;

  const fetchCitationNumber = async () => {
    if (user === null) return null;
    await setAuthToken(user);

    let response = await axiosInstance.get(`/user/number`);
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['citationNumber', email],
    queryFn: () => fetchCitationNumber(),
  });

  return data;
};

export default useCitationNumber;
export { useCitationNumber };
