import { Outlet } from "react-router-dom";
import { useState } from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import useAuth from "../../hooks/useAuth";
import NavBar from "./NavBar";
import TopBar from "components/TopBar";
import { Trans } from "react-i18next";

const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "100%",
  flex: "1 1 auto",
  paddingTop: "64px"
}));

const ContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "20%",
  },
  paddingBottom: 10,
}));

const LegalLayout = () => {
  const { user, logout } = useAuth();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const onMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <Wrapper>
        <TopBar
          user={user}
          handles={{ onMobileNavOpen: onMobileNavOpen, logout: logout }}
          Trans={Trans}
          menu={true}
        />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <ContentDiv>
          <Container maxWidth="md">
            <Outlet />
          </Container>
        </ContentDiv>
      </Wrapper>
    </div>
  );
};

export default LegalLayout;
