import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';

// This is our backup import in case we cannot pull translations over http
/* 
import translationEN from '../../public/static/i18n/en-US/resource.json';
const localResources = {
  en: {
    translation: translationEN
  }
}
*/

/* eslint-disable no-restricted-globals */
const supportedLngs = ['en-US', 'zh', 'es'];
const translationPath =
  process.env.REACT_APP_MODE === 'testing'
    ? 'https://localhost:8081/web-translations/{{lng}}/{{ns}}.json'
    : 'https://cdn.jsdelivr.net/gh/citasion/web-translations@1/{{lng}}/{{ns}}.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // method allows for loading over http and fallback over local
  .use(ChainedBackend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
    compatibilityJSON: 'v3',
    debug: true,
    fallbackLng: 'en-US',
    supportedLngs: supportedLngs,
    ns: ['legals', 'translation', 'blogs', 'docs'],
    defaultNS: 'translation',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends: [
        // lazy loading translations
        resourcesToBackend((language, namespace, callback) => {
          import(`../locales/${language}/${namespace}.json`)
            .then((resources) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        }),
        // Otherwise do http retrieval
        HttpBackend,
      ],
      /* eslint-disable no-restricted-globals */
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
        {
          // retrieves bleeding edge. If you want versioning include @1 after repo name before /
          loadPath: translationPath,
        },
      ],
    }
  });

export default i18n;
export { i18n, supportedLngs };
