import PropTypes from "prop-types";
import { Box, Toolbar, IconButton, SvgIcon } from "@mui/material";
import Logo from "components/Logo";
import Account from "../Account";
import MenuIcon from "@mui/icons-material/Menu";
import AppBarCustomized from "./AppBarCustomized";

const MenuUserAppBar = ({ user, handles, Trans, menu = false }) => {
  if (menu)
    return (
      <AppBarCustomized>
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton
            sx={{ display: { xs: "block", lg: "none" } }}
            color="inherit"
            onClick={handles.onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <Logo sx={{ display: { xs: "block", md: "none" } }} user={user} />
          <Box ml={2} flexGrow={1} />
          <Box ml={2}>
            <Account user={user} handles={handles} Trans={Trans} />
          </Box>
        </Toolbar>
      </AppBarCustomized>
    );

  return (
    <AppBarCustomized>
      <Toolbar sx={{ minHeight: 64 }}>
        <Logo sx={{ display: { xs: "block", md: "none" } }} user={user} />
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account user={user} handles={handles} Trans={Trans} />
        </Box>
      </Toolbar>
    </AppBarCustomized>
  );
};

MenuUserAppBar.propTypes = {
  user: PropTypes.object,
  handles: PropTypes.object,
  Trans: PropTypes.func,
};

export default MenuUserAppBar;
