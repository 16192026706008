// const getCookie = (name) => {
//   let v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
//   let res =  (v === null) ? null : v[2];
//   return res;
// }

const getIdToken = async (user) => {
  // // buggy but efficient
  // let idToken = getCookie("citasion-idToken");
  // if (idToken === null || idToken === "") {
  //   idToken = await user.getIdToken();
  //   document.cookie = `citasion-idToken=${idToken}; max-age=1000; path=/`;
  // }
  // return idToken;

  // working but inefficient
  let idToken = await user.getIdToken();
  return idToken;
};

export default getIdToken;