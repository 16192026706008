import { Outlet } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import NavBar from "./NavBar";
import TopBar from "components/TopBar";
import useAuth from "hooks/useAuth";
import { Trans } from "react-i18next";

const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.dark,
  minHeight: "100%",
  flex: "1 1 auto",
  paddingTop: 64,
}));

const ContentDiv = styled("div")(({ theme }) => ({
  width: "100%",
  flex: "1 1 auto",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 256,
  },
}));

const DashboardLayout = () => {
  const { user, logout } = useAuth();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const onMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const onMobileClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <Wrapper>
        <TopBar
          user={user}
          handles={{ onMobileNavOpen: onMobileNavOpen, logout: logout }}
          Trans={Trans}
          menu={true}
        />
        <NavBar
          user={user}
          openMobile={isMobileNavOpen}
          handles={{ onMobileClose: onMobileClose }}
          Trans={Trans}
        />
        <ContentDiv>
          <Outlet />
        </ContentDiv>
      </Wrapper>
    </div>
  );
};

export default DashboardLayout;
