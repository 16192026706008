import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from '../utils/axios';

const useAllProjects = () => {
  const { user } = useAuth();

  const fetchAllProjects = async () => {
    if (user === null) return null;
    await setAuthToken(user);
    let response = await axiosInstance.get(`/getAllProjectsForWeb`);
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getAllProjects', user],
    queryFn: () => fetchAllProjects(),
    select: (data) => {
      // temporary fix for projects with no title
      for (let i = 0; i < data.length; i++) {
        let project = data[i];
        if (project.title === undefined || project.title === null) {
          project.title = 'Untitled Project';
        } else if (typeof project.title === 'object' && project.title.title) {
          project.title = project.title.title;
        }
      }
      return data;
    },
  });

  return data;
};

const useMyProjects = () => {
  const { user } = useAuth();

  const fetchMyProjects = async () => {
    if (user === null) return null;
    await setAuthToken(user);
    let response = await axiosInstance.get(`/projects/my`);
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getMyProjects', user],
    queryFn: () => fetchMyProjects(),
    select: (data) => {
      // temporary fix for projects with no title
      for (let i = 0; i < data.length; i++) {
        let project = data[i];
        if (project.title === undefined || project.title === null) {
          project.title = 'Untitled Project';
        } else if (typeof project.title === 'object' && project.title.title) {
          project.title = project.title.title;
        }
      }
      return data;
    },
  });

  return data;
};

const useSharedProjects = () => {
  const { user } = useAuth();

  const fetchSharedProjects = async () => {
    if (user === null) return null;
    await setAuthToken(user);
    let response = await axiosInstance.get(`/projects/shared`);
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getSharedProjects', user],
    queryFn: () => fetchSharedProjects(),
    select: (data) => {
      // temporary fix for projects with no title
      for (let i = 0; i < data.length; i++) {
        let project = data[i];
        if (project.title === undefined || project.title === null) {
          project.title = 'Untitled Project';
        } else if (typeof project.title === 'object' && project.title.title) {
          project.title = project.title.title;
        }
      }
      return data;
    },
  });

  return data;
};

export default useAllProjects;
export { useAllProjects, useMyProjects, useSharedProjects };
