import { axiosInstance } from "./axios";

async function createAccount(user, languageCode="en") {
  try {
    user.name = user.displayName ? user.displayName : user.email;
    user.avatar = user.photoURL
      ? user.photoURL
      : `https://ui-avatars.com/api/?name=${user.displayName}`;
    const idToken = await user.getIdToken();
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${idToken}`;
    const response = await axiosInstance.post("/users/create", {
      name: user.name,
      avatar: user.avatar,
      language: languageCode.substring(0, 2).toLowerCase(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false
  }
}

export default createAccount;
