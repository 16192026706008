import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';

const useProject = (email, id) => {
  const { user } = useAuth();

  const fetchMyProjects = async () => {
    if (user === null) return null;

    await setAuthToken(user);
    let response = await axiosInstance.get(`/projects/${email}/${id}`);
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getProject', email, id],
    queryFn: () => fetchMyProjects(),
    select: (data) => {
      // temporary fix for projects with no title
      if (data.title === undefined || data.title === null) {
        data.title = 'Untitled data';
      } else if (typeof data.title === 'object' && data.title.title) {
        data.title = data.title.title;
      }
      return data;
    },
  });

  return data;
};

export default useProject;
export { useProject };
