import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';

const useUser = () => {
  const { user } = useAuth();

  const fetchUser = async () => {
    if (user === null) return null;

    await setAuthToken(user);
    let response = await axiosInstance.get('/user');
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getUser', user],
    queryFn: () => fetchUser(),
  });

  return data;
};

export default useUser;
export { useUser };
