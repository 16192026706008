import { Avatar, Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const UserInfo = ({ tier, user, Trans }) => {
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Avatar
          alt="User"
          sx={{ width: 64, height: 64 }}
          src={
            user.photoURL
              ? user.photoURL
              : `https://ui-avatars.com/api/?name=${user.displayName}`
          }
        />
      </Box>
      <Box mt={2} textAlign="center">
        <Typography variant="h5" color="textPrimary">
          {user.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <Trans i18nKey="layout.DashboardLayout.NavBar.describe">
            Your Tier:
          </Trans>{" "}
          {tier === "...loading" ? (
            <Trans i18nKey="layout.DashboardLayout.NavBar.status1">
              "Loading..."
            </Trans>
          ) : tier === "free" ? (
            <Link
              component={RouterLink}
              sx={{ textDecoration: "none !important" }}
              to="/go-premium"
            >
              <Trans i18nKey="layout.DashboardLayout.NavBar.status2">
                {{ tier }}
              </Trans>
            </Link>
          ) : (
            <Link
              component={RouterLink}
              sx={{ textDecoration: "none !important" }}
              to="/account/subscription"
            >
              <Trans i18nKey="layout.DashboardLayout.NavBar.status3">
                {{ tier }}
              </Trans>
            </Link>
          )}
        </Typography>
      </Box>
    </>
  );
};

export default UserInfo;