import { Link } from "react-router-dom";

const Logo = ({user = null}) => {
  return (
    <Link to={user ? "/home" : "/"}>
      <img
        alt="Logo"
        src="/static/images/logo/logo.svg"
        style={{
          width: 42,
        }}
      />
    </Link>
  );
};

export default Logo;
