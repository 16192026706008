import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTargetUrl } from '../utils/languageMap';

const FootBar = memo(() => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        paddingTop: 1,
        paddingBottom: 2,
        color: '#e6e6e6',
      }}
    >
      <Container maxWidth='lg'>
        <Box mt={5} ml={5} mr={5}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography component='div'>
                <Box sx={{ fontWeight: 'bold', m: 1 }}>
                  {t('globals.product')}
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <Link
                    to={getTargetUrl('/go-premium')}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                  >
                    {t('globals.plans')}
                  </Link>
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <a
                    href={process.env.REACT_APP_CHROME_EXTENSION_URL}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('globals.chromeExtension')}
                  </a>
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <a
                    href={process.env.REACT_APP_FIREFOX_EXTENSION_URL}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('globals.firefoxAddon')}
                  </a>
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <a
                    href={process.env.REACT_APP_EDGE_EXTENSION_URL}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('globals.edgeAddon')}
                  </a>
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <a
                    href={process.env.REACT_APP_WORD_ADDIN_URL}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('globals.wordAddin')}
                  </a>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography component='div'>
                <Box sx={{ fontWeight: 'bold', m: 1 }}>
                  {t('globals.trust')}
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <Link
                    to={getTargetUrl('/legal/terms')}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                  >
                    {t('globals.terms')}
                  </Link>
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <Link
                    to={getTargetUrl('/legal/privacy')}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                  >
                    {t('globals.privacy')}
                  </Link>
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <Link
                    to={getTargetUrl('/legal/privacy-extension')}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                  >
                    {t('globals.extensionPrivacy')}
                  </Link>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography component='div'>
                <Box sx={{ fontWeight: 'bold', m: 1 }}>
                  {t('globals.connect')}
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <Link
                    to={getTargetUrl('/support')}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                  >
                    {t('globals.help')}
                  </Link>
                </Box>
                {/* <Box sx={{ fontWeight: "light", m: 1 }}>
                  <a
                    href={process.env.REACT_APP_FACEBOOK_URL}
                    style={{
                      fontWeight: 400,
                      color: "#e6e6e6",
                      textDecoration: "none",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("globals.facebook")}
                  </a>
                </Box> */}
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <a
                    href={process.env.REACT_APP_TWITTER_URL}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('globals.twitter')}
                  </a>
                </Box>
                <Box sx={{ fontWeight: 'light', m: 1 }}>
                  <a
                    href={process.env.REACT_APP_LINKEDIN_URL}
                    style={{
                      fontWeight: 400,
                      color: '#e6e6e6',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('globals.linkedin')}
                  </a>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant='caption' display='block' sx={{ margin: 1 }}>
            2022 © Citasion LLC.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
});

export default FootBar;
