import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  if (isAuthenticated && location && location.state && location.state.referrer)
    return <Navigate to={location.state.referrer} />;
  else if (isAuthenticated) return <Navigate to="/home" />;
  else return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
