import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';
import { processOneCitation } from 'utils/processCitationList';

const useCitation = (projectEmail, projectId, citationIds) => {
  const { user } = useAuth();

  const fetchACitation = async () => {
    if (user === null) return null;

    await setAuthToken(user);
    let response = await axiosInstance.get(
      `/citations/getbyids/${projectEmail}/${projectId}/${citationIds}`
    );
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getACitation', projectEmail, projectId, citationIds],
    queryFn: () => fetchACitation(),
    select: (data) => {
      console.log('data: ' + data); // debug

      let res = [];
      for (let i = 0; i < data.length; i++) {
        let item = JSON.parse(JSON.stringify(data[i]));
        processOneCitation(item);
        console.log('item: ' + item);
        res.push(item);
      }

      console.log('res: ' + res);

      return res;
    },
  });

  return data;
};

export default useCitation;
export { useCitation };
