// method to process new raw citation list from the server
const processCitationList = (aListOfRawCitations, allProjects) => {
  let newItems = [];

  for (let citation of aListOfRawCitations) {
    processOneCitation(citation, allProjects);
    newItems.push(citation);
  }

  return newItems;
};

// process one citation
const processOneCitation = (item, allProjects = null) => {
  processSpecialChar(item);
  if (item.author) item.author = processAuthors(item.author);
  if (item.editor) item.editor = processAuthors(item.editor);
  if (item.pages) item.pages = processPages(item.pages);

  if (allProjects === null) return;

  // add projects in case projects are already loaded
  item.projectNames = [];
  if (allProjects && allProjects.length > 0) {
    for (let project of allProjects) {
      if (item.projects && item.projects.includes(project.id)) {
        item.projectNames.push(project.title);
      }
    }
  }
};

// process author list for display
const processAuthors = (authors) => {
  if (authors) {
    return authors.split(' and ').join('; ');
  } else {
    return authors;
  }
};

// process pages
const processPages = (pages) => {
  return pages.split('--').join('-');
};

// process special character for display
const processSpecialChar = (item) => {
  for (let key of Object.keys(item)) {
    if (typeof item[key] !== 'string') continue;
    item[key] = item[key].replaceAll('\\&', '&');
  }
};

export default processCitationList;
export { processCitationList, processOneCitation };