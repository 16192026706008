import { useQuery } from 'react-query';

const useFaqData = (lang = 'en', flag = null) => {
  // fetch faq
  const fetchFaq = async (lang) => {
    let response;
    if (lang === 'zh') {
      response = await fetch('/static/data/ZH/guideFaq.json');
    } else if (lang === 'es') {
      response = await fetch('/static/data/ES/guideFaq.json');
    } else {
      response = await fetch('/static/data/EN/guideFaq.json');
    }
    const data = await response.json();
    return data.guideFaq;
  };

  const { data } = useQuery({
    queryKey: ['getCategory', lang, flag],
    queryFn: () => fetchFaq(lang),
    select: (data) => {
      if (flag) {
        return data.filter((item) => item.type.id === flag);
      } else {
        // reorganize data by type
        const result = [];
        data.forEach((item) => {
          let found = false;
          for (let i = 0; i < result.length; i++) {
            if (result[i].type === item.type.name) {
              result[i].items.push(item);
              found = true;
            }
          }
          if (!found) {
            result.push({
              type: item.type.name,
              items: [item],
            });
          }
        });
        return result;
      }
    },
  });

  console.log('data', data);

  return data;
};

export default useFaqData;
export { useFaqData };
