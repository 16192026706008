import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import NavBar from './NavBar';
import TopBar from 'components/TopBar';
import { Trans } from 'react-i18next';

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100%',
  flex: '1 1 auto',
  paddingTop: 64,
  paddingBottom: 64,
  width: '100%',
}));

const ContentDiv = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '300px',
  },
}));

const ArticleLayout = () => {
  const { user, logout } = useAuth();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const onMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Wrapper>
        <TopBar
          user={user}
          handles={{ onMobileNavOpen: onMobileNavOpen, logout: logout }}
          Trans={Trans}
          menu={true}
        />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <Container maxWidth='lg'>
          <ContentDiv>
            <Outlet />
          </ContentDiv>
        </Container>
      </Wrapper>
    </div>
  );
};

export default ArticleLayout;
