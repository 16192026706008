import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { getTargetUrl } from "utils/languageMap";


const Account = ({ user, handles, Trans }) => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const logout = handles.logout;

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      const redirectUrl = getTargetUrl("/");
      navigate(redirectUrl);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          sx={{ height: 32, width: 32, marginRight: (theme) => theme.spacing(1) }}
          src={
            user.photoURL
              ? user.photoURL
              : `https://ui-avatars.com/api/?name=${user.displayName}`
          }
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {user.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        sx={{ width: 200 }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/account">
          <Trans i18nKey="layout.DashboardLayout.TopBar.Account">Account</Trans>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Trans i18nKey="layout.DashboardLayout.TopBar.Logout">Logout</Trans>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
