import { useQuery } from 'react-query';
import { getTargetUrl } from 'utils/languageMap';


const useGuideNavData = (lang) => {
  // fetch categories
  const fetchCategory = async (lang) => {
    let response;
    if (lang === 'zh') {
      response = await fetch('/static/data/ZH/guideCategory.json');
    } else if (lang === 'es') {
      response = await fetch('/static/data/ES/guideCategory.json');
    } else {
      response = await fetch('/static/data/EN/guideCategory.json');
    }
    const data = await response.json();
    return data.guideCategory;
  };

  // fetch guide list
  const fetchGuide = async (lang) => {
    let response;
    if (lang === 'zh') {
      response = await fetch('/static/data/ZH/guideList.json');
    } else if (lang === 'es') {
      response = await fetch('/static/data/ES/guideList.json');
    } else {
      response = await fetch('/static/data/EN/guideList.json');
    }
    const data = await response.json();
    return data.guideList;
  };

  // frontend data setter
  const fetchAll = async (lang) => {
    const guide = await fetchGuide(lang);
    const category = await fetchCategory(lang);

    // construct navData
    for (let i = 0; i < category.length; i++) {
      const section = category[i];
      for (let j = 0; j < guide.length; j++) {
        const item = guide[j];
        if (section.id === item.categoryId) {
          if (!section.items) section.items = [];
          section.items.push({
            id: item.id,
            title: item.title,
            href: getTargetUrl(`/support/guide/${item.id}`),
          });
        }
      }
    }

    return category;
  };

  const { data } = useQuery({
    queryKey: ['getCategory', lang],
    queryFn: () => fetchAll(lang),
  });

  return data;
};

export default useGuideNavData;
export { useGuideNavData };
