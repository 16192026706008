import app from "../lib/firebase";
import { createContext, useState, useEffect } from "react";
import SplashScreen from "../components/SplashScreen";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import createAccount from "../utils/createAccount";
import { i18n } from "../utils/i18";

const initialAuthState = {
  isAuthenticated: false,
  initializing: true,
  user: null,
};

const AuthContext = createContext({
  ...initialAuthState,
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(initialAuthState);

  const logout = () => {
    const auth = getAuth();
    return signOut(auth);
  };

  const setLogin = (user) => {
    setState({
      isAuthenticated: true,
      initializing: false,
      user: user,
    });
  };

  const setLogout = () => {
    setState({
      isAuthenticated: false,
      initializing: false,
      user: null,
    });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // set language code
      const languageCode =
        i18n.language === "" || typeof i18n.language === "undefined"
          ? "en-US"
          : i18n.language;

      if (user) {

        if (user.metadata.creationTime === user.metadata.lastSignInTime) {
          await createAccount(user, languageCode);
        }
        setLogin(user);

        // if (user.providerData.length > 0) {
        //   console.log("Social login used!");
        //   // email verified accounts - either email or facebook/google etc.
        //   // Check if this is the first time the account was created
        //   if (user.metadata.creationTime === user.metadata.lastSignInTime) {
        //     await createAccount(user, languageCode);
        //   }
        //   setLogin(user);
        // } else {
        //   console.log("email login used!");
        //   const emailCookie = document.cookie
        //     .split(";")
        //     .some((item) => item.trim().startsWith("email-verify-sent="));

        //   if (!emailCookie) {
        //     console.log("Sent verification email");
        //     document.cookie = "email-verify-sent=yes; max-age=3600";
        //     // Sent email only once
        //     // if (user.metadata.creationTime === user.metadata.lastSignInTime) {
        //     app.auth().languageCode = languageCode;
        //     await app.auth().currentUser.sendEmailVerification();
        //     await createAccount(user, languageCode);
        //     // }
        //     // await app.auth().signOut();
        //     setLogout();

        //     // redirect
        //     navigate("/emailVerification");
        //   } else {
        //     console.log("Wait for verification");
        //     setLogout();
        //   }
        // }
      } else {
        document.cookie = `citasion-token=_; max-age=0`;
        document.cookie = `citasion-project-active=_; max-age=0`;
        document.cookie = `citasion-tier=_; max-age=0`;
        document.cookie = `citasion-idToken=_; max-age=0`;
        document.cookie = `citasion-user-email=_; max-age=0`;
        setLogout();
      }
    });

    return unsubscribe;
  }, []);

  if (state.initializing) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
