import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Button, Collapse, List, ListItem, ListSubheader } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import CreateIcon from '@mui/icons-material/Create';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(1.25),
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontSize: 16,
}));

const iconMap = {
  DriveFileMoveIcon: <DriveFileMoveIcon />,
  ControlPointDuplicateIcon: <ControlPointDuplicateIcon />,
  CreateIcon: <CreateIcon />,
  ImportContactsIcon: <ImportContactsIcon />,
  PlayCircleFilledWhiteIcon: <PlayCircleFilledWhiteIcon />,
}

const NavList = ({ section }) => {
  const [open, setOpen] = useState(
    section.header === 'Dashboard' ? true : false
  );

  const handleToggle = () => {
    setOpen(!open);
  };

  const Icon = iconMap[section.icon];

  return (
    <List
      key={section.id}
      subheader={
        <ListSubheader disableGutters disableSticky>
          <NavButton
            onClick={handleToggle}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingRight: 10,
                }}
              >
                {Icon}
              </span>
              {section.title}
            </span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 20,
              }}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </span>
          </NavButton>
        </ListSubheader>
      }
    >
      <Collapse in={open}>
        <List disablePadding>
          {section.items.map((item) => {
            return (
              <ListItem
                sx={{ display: 'flex', paddingTop: 0, paddingBottom: 0 }}
                disableGutters
                key={item.title}
              >
                <NavButton
                  component={RouterLink}
                  to={item.href}
                  sx={{
                    paddingLeft: (theme) => theme.spacing(3),
                  }}
                >
                  <span>{item.title}</span>
                </NavButton>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default NavList;
