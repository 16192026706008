import { Suspense, Fragment, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import AuthGuard from './components/AuthGuard';
import AuthGuardIframe from './components/AuthGuardIframe';
import GuestGuard from './components/GuestGuard';
import GuestGuardIframe from './components/GuestGuardIframe';
import DashboardLayout from './layouts/DashboardLayout';
import ArticleLayout from './layouts/ArticleLayout';
import LegalLayout from './layouts/LegalLayout';
import LandingLayout from './layouts/LandingLayout';
import SimpleDashboardLayout from './layouts/SimpleDashBoardLayout';
import { languageMap } from './utils/languageMap';


const renderRoutes = (routes = []) => {
  return routes.map((route) => {
    // routes with children
    if (route.children) {
      let Layout = route.element ? route.element : Fragment;
      let Guard = route.guard ? route.guard : Fragment;
      route.element = (
        <Guard>
          <Layout />
        </Guard>
      );

      for (let i = 0; i < route.children.length; i++) {
        let Element = route.children[i].element;
        let Guard = route.children[i].guard
          ? route.children[i].guard
          : Fragment;

        route.children[i].element = (
          <Guard>
            <Suspense fallback={<SplashScreen />}>
              <Element />
            </Suspense>
          </Guard>
        );
      }
    } else {
      // routes without children
      let Element = route.element;
      let Guard = route.guard ? route.guard : Fragment;

      route.element = (
        <Guard>
          <Suspense fallback={<SplashScreen />}>
            <Element />
          </Suspense>
        </Guard>
      );
    }

    return route;
  });
};

// const renderRoutes = (routes = []) => {
//   return routes.map((route, i) => {
//     // routes with children: may or may not have a path
//     if (route.routes) {
//       let path = route.path ? route.path : false;
//       let Layout = route.layout ? route.layout : Fragment;
//       let Guard = route.guard ? route.guard : Fragment;

//       return (
//         <Route
//           key={i + path}
//           path={path}
//           element={
//             <Guard>
//               <Layout />
//             </Guard>
//           }
//         >
//           {renderRoutes(route.routes)}
//         </Route>
//       );
//     } else {
//       // routes without children
//       let Component = route.component;
//       let path = route.path ? route.path : false;
//       let index = route.path ? false : true;
//       let Guard = route.guard ? route.guard : Fragment;

//       if (route.layout) {
//         // parent route: with a layout but no children
//         let Layout = route.layout;
//         return (
//           <Route
//             key={i + path}
//             element={
//               <Guard>
//                 <Layout />
//               </Guard>
//             }
//           >
//             <Route
//               path={path}
//               element={
//                 <Suspense fallback={<SplashScreen />}>
//                   <Component />
//                 </Suspense>
//               }
//             />
//           </Route>
//         );
//       } else {
//         // child route: index route or with a path, or
//         // parent route: with no children or layout
//         return (
//           <Route
//             key={i + path}
//             path={path}
//             index={index}
//             element={
//               <Guard>
//                 <Suspense fallback={<SplashScreen />}>
//                   <Component />
//                 </Suspense>
//               </Guard>
//             }
//           />
//         );
//       }
//     }
//   });
// };

const landingPages = [
  {
    element: LandingLayout,
    children: [
      {
        path: '/',
        element: lazy(() => import('./views/home/HomeView')),
      },
      {
        path: '/zh',
        element: lazy(() => import('./views/home/HomeView')),
      },
      {
        path: '/es',
        element: lazy(() => import('./views/home/HomeView')),
      },
    ],
  },
];

const otherPages = [
  {
    element: SimpleDashboardLayout,
    children: [
      // {
      //   path: '/',
      //   element: lazy(() => import('./views/home/HomeView')),
      // },
      {
        guard: GuestGuard,
        path: '/login',
        element: lazy(() => import('./views/auth/LoginView')),
      },
      {
        guard: GuestGuard,
        path: '/register',
        element: lazy(() => import('./views/auth/RegisterView')),
      },
      {
        guard: GuestGuard,
        path: '/authRedirect',
        element: lazy(() => import('./views/auth/ResetPasswordView')),
      },
      {
        guard: AuthGuard,
        path: '/enablePassword',
        element: lazy(() => import('./views/auth/EnablePasswordView')),
      },
      {
        guard: AuthGuard,
        path: '/enablePassword/reauthentication',
        element: lazy(() => import('./views/auth/EnablePasswordView/ReAuth')),
      },
      {
        path: '/go-premium',
        element: lazy(() => import('./views/campaign/CampaignStarter')),
      },
      {
        path: '/premium',
        guard: AuthGuard,
        element: lazy(() => import('./views/pricing/PremiumView')),
      },
      {
        path: '/updatePayment',
        guard: AuthGuard,
        element: lazy(() => import('./views/pricing/UpdatePayment')),
      },
      {
        path: '/changePlan',
        guard: AuthGuard,
        element: lazy(() => import('./views/pricing/PlanChange')),
      },
      {
        path: '/support',
        element: lazy(() => import('./views/support/Home')),
      },
      {
        path: '/support/faq',
        element: lazy(() => import('./views/support/Faq')),
      },
      {
        path: '/support/contact',
        element: lazy(() => import('./views/support/Contact')),
      },
      {
        path: '/extension-uninstall',
        element: lazy(() => import('./views/uninstall')),
      },
      {
        path: '/notAuthorized',
        element: lazy(() => import('./views/errors/NotAuthorized')),
      },
      {
        path: '/error',
        element: lazy(() => import('./views/errors/UnknownError')),
      },
    ],
  },
  {
    element: DashboardLayout,
    guard: AuthGuard,
    children: [
      {
        path: '/home',
        element: lazy(() => import('./views/references/ReferenceBrowseView')),
      },
      {
        path: '/projects',
        element: lazy(() => import('./views/project/ProjectBrowseView')),
      },
      {
        path: '/account',
        element: lazy(() => import('./views/account/AccountView')),
      },
      {
        path: '/account/:tab',
        element: lazy(() => import('./views/account/AccountView')),
      },
      {
        path: '/app',
        element: lazy(() => import('./views/application')),
      },
      {
        path: '/invoices',
        element: lazy(() => import('./views/invoice/InvoiceListView')),
      },
      {
        path: '/invoices/:email/:id',
        element: lazy(() => import('./views/invoice/InvoiceDetailView')),
      },
      {
        path: '/projects/:email/:id',
        element: lazy(() => import('./views/project/ProjectDetailsView')),
      },
      {
        path: '/projects/:email/:id/citations/:citationIds',
        element: lazy(() => import('./views/project/ProjectSingleCitationView')),
      },
    ],
  },
  {
    element: LegalLayout,
    children: [
      {
        path: '/legal',
        element: lazy(() => import('./views/legals')),
      },
      {
        path: '/legal/:id',
        element: lazy(() => import('./views/legals')),
      },
    ],
  },
  {
    element: ArticleLayout,
    children: [
      {
        path: '/support/guide',
        element: lazy(() => import('./views/support/Guide')),
      },
      {
        path: '/support/guide/:id',
        element: lazy(() => import('./views/support/Article')),
      },
    ],
  },
  {
    path: '/logout',
    element: lazy(() => import('./views/auth/LogoutView')),
  },
  {
    path: '/iframe/login',
    guard: GuestGuardIframe,
    element: lazy(() => import('./views/iframe/LoginIframeView')),
  },
  {
    path: '/iframe/home',
    guard: AuthGuardIframe,
    element: lazy(() => import('./views/iframe/ReferenceIframe')),
  },
];

// const wildcard = {
//   path: '*',
//   element: SimpleDashboardLayout,
//   element: lazy(() => import('./views/errors/NotFoundView')),
// };

const wildcard = {
  element: SimpleDashboardLayout,
  children: [
    {
      path: '*',
      element: lazy(() => import('./views/errors/NotFoundView')),
    },
  ],
};

const translatedRoute = (lang, routes) => {
  const results = [];

  for (const route of routes) {
    if (route.children && route.children.length > 0) {
      // put together a block of routes
      const newBlock = {};
      newBlock.path = `/${lang}`;
      if (route.element) newBlock.element = route.element;
      if (route.guard) newBlock.guard = route.guard;
      newBlock.children = route.children.map((childRoute) => {
        let newPath = childRoute.path.substring(1);
        if (newPath.length > 0) return { ...childRoute, path: newPath };
        else {
          // index route for a different language
          const { path, ...newRoute } = childRoute;
          return newRoute;
        }
      });

      results.push(newBlock);
    } else {
      // put together a single route
      const newRoute = {};
      newRoute.path = `/${lang}${route.path}`;
      newRoute.element = route.element;
      if (route.guard) newRoute.guard = route.guard;

      results.push(newRoute);
    }
  }

  // results.push(wildcard);

  // console.log(results);

  return results;
};

const translateRoutes = (routes) => {
  const results = [];

  // prepare all languages
  const langs = Object.values(languageMap);
  langs.shift();

  // prepare all language routes
  langs.map((lang) => {
    results.push(...translatedRoute(lang, routes));
  });

  // return all routes
  return results;
};

const routes = [
  ...landingPages,
  ...otherPages,
  ...translateRoutes(otherPages),
  {
    path: '/notFound',
    element: lazy(() => import('./views/errors/NotFoundView')),
  },
  wildcard,
];

// console.log(routes);

// const RenderAllRoutes = () => {
//   return <Routes>{renderRoutes(routes)}</Routes>;
// };

const router = createBrowserRouter(renderRoutes(routes));

export default router;
