import { useCallback } from 'react';
import useAuth from './useAuth';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { axiosInstance, setAuthToken } from '../utils/axios';

const useTier = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  // fetch tier server function
  const fetchTier = async () => {
    if (user === null) return null;

    await setAuthToken(user);
    let response = await axiosInstance.get('/user/tier');
    return response.data.result;
  };

  // downgrade server function
  const downgradeFunction = async () => {
    if (user === null) return null;

    // let firebase know
    await setAuthToken(user);
    let response = await axiosInstance.get('/subscription/downgrade');
    return response.data.result;
  };

  // downgrade mutation
  const downgradeMutation = useMutation({
    mutationFn: () => downgradeFunction(),
    onMutate: () => {},
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['getTier', user]);
    },
  });

  const { status, data } = useQuery({
    queryKey: ['getTier', user],
    queryFn: () => fetchTier(),
    // process the tier data so that only tier is returned
    select: useCallback((data) => {
      const currentTime = new Date();
      const renewTime = new Date(data.renewTime);

      // premium and renewtime has expired
      if (data.tier === 'premium' && currentTime > renewTime) {
        // update payment and renew
        downgradeMutation.mutate();
      }

      return data.tier;
    }, []),
  });

  if (status === 'loading') {
    return '...loading';
  }

  if (status === 'error') {
    return 'free';
  }

  return data;
};

export default useTier;
export { useTier };
