import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";

const GuestGuardIframe = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/iframe/home" />;
  }

  return <>{children}</>;
};

GuestGuardIframe.propTypes = {
  children: PropTypes.node,
};

export default GuestGuardIframe;
