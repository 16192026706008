import { useQuery } from 'react-query';

const useArticle = (id, lang) => {
  // fetch article meta
  const fetchMeta = async () => {
    let response;
    if (lang === 'zh') {
      response = await fetch(`/static/data/ZH/articles/${id}.json`);
    } else if (lang === 'es') {
      response = await fetch(`/static/data/ES/articles/${id}.json`);
    } else {
      response = await fetch(`/static/data/EN/articles/${id}.json`);
    }
    const data = await response.json();
    return data.guideArticle;
  };

  // fetch article content
  const fetchContent = async () => {
    // prepare response
    let response, data;

    if (lang === 'zh') {
      response = await fetch(`/static/data/ZH/articles/${id}.md`);
    } else if (lang === 'es') {
      response = await fetch(`/static/data/ES/articles/${id}.md`);
    } else {
      response = await fetch(`/static/data/EN/articles/${id}.md`);
    }
    data = await response.text();
    return data;
  };

  const fetchAll = async () => {
    const article = await fetchMeta();
    article.content = await fetchContent();
    return article;
  };

  const { data } = useQuery({
    queryKey: ['getArticle', lang, id],
    queryFn: () => fetchAll(),
  });

  return data;
};

export default useArticle;
export { useArticle };
