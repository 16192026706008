import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MenuAppBar from "./components/MenuAppBar";
import MenuUserAppBar from "./components/MenuUserAppBar";

const TopBar = ({ user, handles, Trans, menu=false }) => {
  const { t } = useTranslation();

  if (user)
    return (
      <MenuUserAppBar user={user} handles={handles} Trans={Trans} menu={menu} />
    );

  return <MenuAppBar user={user} handles={handles} t={t} menu={menu} />;
};

TopBar.propTypes = {
  user: PropTypes.object,
  handles: PropTypes.object,
  Trans: PropTypes.func,
};

export default TopBar;
