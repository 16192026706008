import useAuth from './useAuth';
import { useQuery } from 'react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';


const useInvoices = () => {
  const { user } = useAuth();

  const fetInvoices = async () => {
    if (user === null) return null;
    await setAuthToken(user);
    let response = await axiosInstance.get('/invoices');
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getInvoices', user],
    queryFn: () => fetInvoices(),
  });

  return data;
};

const useInvoice = (id) => {
  const { user } = useAuth();

  const fetInvoice = async () => {
    if (user === null) return null;
    await setAuthToken(user);
    let response = await axiosInstance.get(`/invoices/${id}`);
    return response.data.result;
  };

  const { data } = useQuery({
    queryKey: ['getInvoice', user, id],
    queryFn: () => fetInvoice(),
  });

  return data;
};

export default useInvoices;
export { useInvoices, useInvoice };
