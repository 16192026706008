import useAuth from './useAuth';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';
import useAllProjects from './useProjects';
import processCitationList from 'utils/processCitationList';


const useInfiniteCitation = () => {
  const { user } = useAuth();
  const email = user.email;

  // all projects
  const allProjects = useAllProjects();

  const fetchCitations = async ({ pageParam = 0 }) => {
    if (pageParam === undefined) return;
    await setAuthToken(user);
    const response = await axiosInstance.get(
      `/citations/getlist/${user.email}/noProject/${pageParam}`
    );

    console.log('Infinite citation backend API call triggered'); // debug
    console.log(response); // debug

    return { 
      results: response.data.result.items,
      nextPage: pageParam + 1,
      totalPages: response.data.result.totalPages,
    };
  };

  // infinite loading
  const infiniteLoading = useInfiniteQuery(
    {
      queryKey: ['citations', email],
      queryFn: fetchCitations,
      getNextPageParam: (lastPage) => {
        if (lastPage.nextPage < lastPage.totalPages) return lastPage.nextPage;
        return undefined;
      },
      select: (data) => {
        // console.log(data); // debug

        const pages = data.pages;

        let res = [];
        for (let i = 0; i < pages.length; i++) {
          let newPage = JSON.parse(JSON.stringify(pages[i].results));
          let item = processCitationList(newPage, allProjects);
          res.push(...item);
        }

        return res;
      },
    }
  );

  return infiniteLoading;
};

export default useInfiniteCitation;
export { useInfiniteCitation };
